/* .box-url{
    position: relative;
    padding: 30px;
    background: #f6f9fc;
    border: solid 1px rgba(0, 0, 0, 0.2);
    display: block;
    border-radius: 20px;
    box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 10%);
    transition: 0.3s;
    margin-right: 21px;
}; */

.rectangle{
    position: absolute;
    right: 45px;
    padding: 2px 10px;
    top: 26px;
    border-radius: 8px;
}

.circle{
    margin: inherit;
}



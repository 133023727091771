$color: #D93A2B;

.metamask {
    cursor: pointer;
}

.torus {
    cursor: pointer;

    .box-url {
        height: 100%;
    }
}

.separator {
    border-bottom: 2px solid lightgrey;
    margin-top: 20px;
    margin-bottom: 20px;
}

.social-login {
    // background-color: white;
    color: white;
    border-radius: 15px;
    max-width: fit-content;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    img {
        height: 35px;
        width: auto;
        padding-right: 10px;
    }
    margin-bottom: 10px;
    position: relative;

    .spinner {
        position: absolute;
        left: -20px;
        margin-left: 50%;
    }
}

.login-spinner {
    color: $color !important;
    position: absolute;
}

.login-loading-container {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(68, 68, 68, 0.396);
    z-index: 100;
    position: fixed;
}

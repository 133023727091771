/*color*/
$white: #F7F7F7;
$gray: #bbb;
$black: #1b1b1b;
$general: #727272;
$black_soft: #212428;
$black_col: #212428;
$black_more: #212428;
$color: #D93A2B; // rgb: 217, 58, 43
$color_more: #820b00;


.banner-scdetail{
}

.img-p{
    width: 150px !important;
    height: 150px !important;
    
}

.text-light-emphasis {
    color: var(--bs-light-text) !important;
  }

  .modal-table{ 
    max-height: 600px; /* Ajusta esta altura según tus necesidades */
    overflow-y: auto;
  }

.table {
    --bs-table-color: $black_soft;
    --bs-table-bg: $black_soft;
    --bs-table-border-color: $black_soft;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: $black_soft;
    --bs-table-striped-bg: $black_soft;
    --bs-table-active-color: $black_soft;
    --bs-table-active-bg: rgba(10, 15, 24, 0.1);
    --bs-table-hover-color: var(--bs-primary-text);
    --bs-table-hover-bg: rgba(64, 96, 144, 0.1);
    width: 100%;
    color: $white;
    border-color: $white;
    margin-bottom: 1rem;
    vertical-align: top;

    border: 1px solid #ccc;
}


  .table > :not(caption) > * > * {
    padding: 1.1rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
  .table > tbody {
    vertical-align: inherit;
  }
  .table > thead {
    vertical-align: bottom;
  }
  
  .table-group-divider {
    border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
  }
  
  .caption-top {
    caption-side: top;
  }
  
  .table-sm > :not(caption) > * > * {
    padding: 0.65rem 0.25rem;
  }
  
  .table-bordered > :not(caption) > * {
    border-width: var(--bs-border-width) 0;
  }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width);
  }
  
  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0;
  }
  .table-borderless > :not(:first-child) {
    border-top-width: 0;
  }
  
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }
  
  .table-striped-columns > :not(caption) > tr > :nth-child(even) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }
  
  .table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color);
  }
  
  .table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
  }
  
  .table-primary {
    --bs-table-color: #0A0F18;
    --bs-table-bg: #d9dfe9;
    --bs-table-border-color: #c4cad4;
    --bs-table-striped-bg: #cfd5df;
    --bs-table-striped-color: #0A0F18;
    --bs-table-active-bg: #c4cad4;
    --bs-table-active-color: #0A0F18;
    --bs-table-hover-bg: #c9cfd9;
    --bs-table-hover-color: #0A0F18;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  .table-secondary {
    --bs-table-color: #0A0F18;
    --bs-table-bg: #dddfe0;
    --bs-table-border-color: #c8cacc;
    --bs-table-striped-bg: #d2d5d6;
    --bs-table-striped-color: #0A0F18;
    --bs-table-active-bg: #c8cacc;
    --bs-table-active-color: #0A0F18;
    --bs-table-hover-bg: #cdcfd1;
    --bs-table-hover-color: #0A0F18;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  
  .table-success {
    --bs-table-color: #0A0F18;
    --bs-table-bg: #d3e8d3;
    --bs-table-border-color: #bfd2c0;
    --bs-table-striped-bg: #c9ddca;
    --bs-table-striped-color: #0A0F18;
    --bs-table-active-bg: #bfd2c0;
    --bs-table-active-color: #0A0F18;
    --bs-table-hover-bg: #c4d8c5;
    --bs-table-hover-color: #0A0F18;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  .table-info {
    --bs-table-color: #0A0F18;
    --bs-table-bg: #ccf2ff;
    --bs-table-border-color: #b9dbe8;
    --bs-table-striped-bg: #c2e7f3;
    --bs-table-striped-color: #0A0F18;
    --bs-table-active-bg: #b9dbe8;
    --bs-table-active-color: #0A0F18;
    --bs-table-hover-bg: #bde1ee;
    --bs-table-hover-color: #0A0F18;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  .table-warning {
    --bs-table-color: #0A0F18;
    --bs-table-bg: #fff7cc;
    --bs-table-border-color: #e7e0ba;
    --bs-table-striped-bg: #f3ebc3;
    --bs-table-striped-color: #0A0F18;
    --bs-table-active-bg: #e7e0ba;
    --bs-table-active-color: #0A0F18;
    --bs-table-hover-bg: #ede6bf;
    --bs-table-hover-color: #0A0F18;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  .table-danger {
    --bs-table-color: #0A0F18;
    --bs-table-bg: #f8d0d8;
    --bs-table-border-color: #e0bdc5;
    --bs-table-striped-bg: #ecc6ce;
    --bs-table-striped-color: #0A0F18;
    --bs-table-active-bg: #e0bdc5;
    --bs-table-active-color: #0A0F18;
    --bs-table-hover-bg: #e6c2ca;
    --bs-table-hover-color: #0A0F18;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  .table-light {
    --bs-table-color: #0A0F18;
    --bs-table-bg: #EEF2F6;
    --bs-table-border-color: #d7dbe0;
    --bs-table-striped-bg: #e3e7eb;
    --bs-table-striped-color: #0A0F18;
    --bs-table-active-bg: #d7dbe0;
    --bs-table-active-color: #0A0F18;
    --bs-table-hover-bg: #dde1e5;
    --bs-table-hover-color: #0A0F18;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  .table-dark {
    --bs-table-color: #ffffff;
    --bs-table-bg: #1E2530;
    --bs-table-border-color: #353b45;
    --bs-table-striped-bg: #29303a;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #353b45;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #2f3540;
    --bs-table-hover-color: #ffffff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1399.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
.nft_coll2{
	width: 410px;
}
.rectangle1{
	display:flex;
	justify-content:center;
	align-items: center;
}
.rectangle2{
	display:flex;
	flex-direction: column;
	justify-content:center;
	align-items: center;
	margin-left: 30px;

}
.circle{
    display:flex;
	justify-content: center;
	
   	
}
.button{
	justify-content:center;
	align-items: center;
	
}
.space{
	display: inline-block;
	width: 50px;
}
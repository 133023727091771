.selectWalletDescription {
    color: grey
}

.wallet-container {
    border: 2px solid gray;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        border-color: black;
        border-width: 3px;
        background-color: #e5817855;
    }

    &.selected {
        border-color: #D93A2B;
        border-width: 2px;
        background-color: #e5817855;
    }

    span {
        text-overflow: ellipsis;
        max-width: 100%;
    }
}

.wallets-scroller {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media only screen and (max-width: 991px) {
    .wallet-container {
        font-size: 0.7rem !important;

        img {
            width: 20px;
            height: 15px;
        }
    }
  }

@media only screen and (max-width: 768px) {
   
  }
  
  @media only screen and (max-width: 600px) {
  
  }
  
  @media only screen and (max-width: 435px) {
    
    .wallet-container {
        font-size: 0.5rem !important;

        img {
            width: 15px;
            height: 10px;
        }
    }
  }

  @media only screen and (max-width: 385px) {
    
    .wallet-container {
        img {
            display: none;
        }
    }
  }
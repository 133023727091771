
div {
    .loading-gear{
        max-width: 50px;
        height: 50px;
    }
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-container {
  border-color: white;
  border: 2px solid;
  border-radius: 10px;
  /* background: #f6f9fc; */
}

.modal-content{
  background: #f6f9fc; 
}

.create-smart{
    justify-content: center;
    display: flex;
  }

.pointer {
  cursor: pointer;
}
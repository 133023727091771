.modal-container {
  border-color: white;
  border: 2px solid;
  border-radius: 10px;
}


.modal-button {
  width: 100%;
  height: 3.125rem;
  border: 3px solid var(--primary-color);
  border-radius: 1.5rem;
  background-color: var(--white-color);
  color: var(--primary-color);
  font: 700 0.875rem/0 "Open Sans", sans-serif;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.5vh;
}

.sub-text {
  color: gray;
  font-size: 14px;
}

.resend {
  background: none;
  border: none;
  color: white !important;
  text-decoration: underline;
}
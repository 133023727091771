.claim-container {
    justify-content: center;
    margin-bottom: 20px;
    color: white;
    gap: 30px
}

.claim-object {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 10px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    width: fit-content;

    & img {
        object-fit: cover;
        object-position: center;
        height: 250px;
        width: auto;
        max-width: 600px;
    }
}

.claimed {
    display: flex;
    align-items: center;
    justify-content: center;
    
    color: white;

    & img {
        object-fit: cover;
        object-position: center;
        height: 50px;
        width: auto;
    }

    & div {
        font-size: 20px;
    }
}

.opensea {
    // margin-left: 15px;
    display: flex;
    gap: 20px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
      }

    & img {
        object-fit: cover;
        object-position: center;
        height: 50px;
        width: auto;
    }
}